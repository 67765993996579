import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { ButtonPrimaryCSS } from 'components/elements/Buttons/ButtonPrimaryBig'
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondary'

// Components
import FormDuuf from 'components/related/Form'
import BlockDefault from 'components/elements/Blocks/BlockDefault'

const Section = styled.section`
  margin-top: -20rem;
`

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    margin-bottom: 2rem;
    max-width: 400px;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: #e5e5e5;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-top: -0.56rem;
    margin-left: 0.5rem;
    margin-bottom: 0;
    padding-right: 6rem;
    line-height: 30px;
  }

  & .form-duuf-checkbox {
    margin-top: -2.5rem;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonPrimaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    color: ${({ theme }) => theme.color.primary};
    font-size: 16px !important;
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    background: ${({ theme }) => theme.color.light};
    padding: 3rem;
    width: 70%;
    margin: 0 auto;
    box-shadow: 1px 1px 12px 0px #404040;
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .form-duuf-group-consent {
    & a:hover {
      text-decoration: underline;
    }
  }
`

const Content = styled(ParseContent)`
  & td > span {
    font-weight: ${({ theme }) => theme.font.weight.regular} !important;
    & a:hover {
      text-decoration: underline !important;
    }
  }
  & img {
    &:hover {
      opacity: 0.75;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.primary} !important;
    text-decoration: none !important;
  }

  & .socials {
    margin-top: 0.75rem;
  }
`

interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <Section>
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-8 mt-5">
          <BlockDefault noBorder noHoverEffect className="p-sm-5 pt-5">
            <ParseContent content={fields.info} />
            <div className="row w-100 justify-content-center mt-4">
              <div className="col-md-7">
                <StyledFormDuuf id={1} privacyUrl="/privacyverklaring" />
              </div>
              <div className="col-md-5 mt-5">
                <Content content={fields.description} />
              </div>
            </div>
          </BlockDefault>
        </div>
      </div>
    </div>
  </Section>
)

export default FormContact
